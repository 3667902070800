import { RequestTracker } from './request-tracker.js';
import getAbsoluteUrl from './url-helpers.js';

function createStartContext(startTime, input, init, baseUrl) {
    const inputIsRequest = isRequest(input);
    const url = inputIsRequest ? input.url : String(input);
    const method = (!!init && init.method) || (inputIsRequest && input.method) || 'GET';
    return { url: getAbsoluteUrl(url, baseUrl), method, startTime, type: 'fetch' };
}
function isRequest(input) {
    return !!input && typeof input === 'object' && !(input instanceof URL);
}
function createFetchRequestTracker(global, clock) {
    const requestTracker = new RequestTracker();
    const originalFetch = global.fetch;
    global.fetch = function fetch(input, init) {
        const startContext = createStartContext(clock.now(), input, init, global.document && global.document.baseURI);
        const onRequestEnd = requestTracker.start(startContext);
        return originalFetch.call(this, input, init).then(response => {
            onRequestEnd({ status: response.status, endTime: clock.now(), state: 'success' });
            return response;
        }).catch(error => {
            onRequestEnd({ error, endTime: clock.now(), state: 'error' });
            throw error;
        });
    };
    return requestTracker;
}

export { createFetchRequestTracker as default };
